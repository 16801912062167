import React from 'react';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import LinkedHeading from '@sentry/static-shared/components/LinkedHeading';
import Arcade from '@sentry/static-shared/components/arcade';
import styled from '@emotion/styled';
import Testimonial from '@sentry/static-shared/components/Testimonial';
import { backgroundMixin, mqMin } from '@sentry/static-shared/utils/css';
import EnhancedImage from '@sentry/static-shared/components/EnhancedImage';
import { chaos } from '@sentry/static-shared/utils/css/patterns';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PromotionalBanner from '@sentry/static-shared/components/organisms/PromotionalBanner';
import Highlight from '@sentry/static-shared/components/Highlight';
import DOMPurify from 'isomorphic-dompurify';
import VimeoEmbed from '@sentry/static-shared/components/VimeoEmbed';
import YouTubeEmbed from '@sentry/static-shared/components/YouTubeEmbed';
import Prose from '@sentry/static-shared/components/Prose';
import slugify from '@sentry/static-shared/utils/slugify';
import MdxHighlight from '@sentry/static-shared/components/MdxHighlight';

const getRichTextRenderOptions = (documentJson, references) => {
  const getReferenceByContentfulId = (id) =>
    references.filter(({ contentful_id }) => id === contentful_id)
    ?.pop() || false;

  const headings = documentJson.content[0].content?.filter(node => node?.nodeType?.includes('heading-'));

  const solutionButtonEnabled = headings.some(h => slugify(h?.content[0]?.value) === 'the-problem')
    && headings.some(h => slugify(h?.content[0]?.value) === 'the-solution');

  return {
    preserveWhitespace: true,
    renderNode: {
      [BLOCKS.LIST_ITEM]: (node, children) => <StyledLi>{children}</StyledLi>,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const containsCode = node.content.find(n => n?.marks && n?.marks[0]?.type === 'code');
        const isInlineCode = containsCode && !!containsCode?.data?.language
        if (isInlineCode) {
          const language = containsCode.data.language
          return (
            <div className='gatsby-highlight' data-language={language}>
              <Highlight className="gatsby-highlight" language={language} showCopyButton={false}>{containsCode.value}</Highlight>
            </div>
          );
        }
        const isMarkupBlock = node.data?.__typename === 'MarkupBlock';
        if (isMarkupBlock) {
          return (
            <StyledMarkup className="blog-content">{children}</StyledMarkup>
          );
        }
        // Check for 'small' type in data attribute
        const containsSmall = node.content.some(n => n.data?.type === 'small');
        if (containsSmall) {
          return (
            <p>
              {node.content.map((n, index) => {
                let content = n.value;
                if (n.data?.type === 'small') {
                  content = <small key={index}>{content}</small>;
                }
                if (n.marks?.some(mark => mark.type === MARKS.ITALIC)) {
                  content = <em key={index}>{content}</em>
                }
                return content;
              })}
            </p>
          );
        }
        // Check for our <table> markdown
        const isHtmlTable = node.content.some(n => n.data?.type === 'htmlTable');
        const isImageHyperlink = node.content.some(n => n.data?.type === 'imageHyperlink');
        const isHtmlVimeoEmbed = node.content.some(n => n.data?.type === 'htmlVimeoEmbed');
        const isHtmlArcadeEmbed = node.content.some(n => n.data?.type === 'htmlArcadeEmbed');
        if (isHtmlArcadeEmbed || isHtmlVimeoEmbed) {
          return isHtmlVimeoEmbed ?
            <p><VimeoEmbed id={node.content[0].value}/></p> :
            <Arcade arcadeURL={node.content[0].value?.trim('?embed')} aspectPercent={78} withChrome={false}/>
        }
        if (isHtmlTable || isImageHyperlink) {
          const html = node.content[0].value;
          return <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}/>;
        }
        return (
          <p>{children}</p>
        );
      },
      [BLOCKS.HEADING_1]: (node, children) => <LinkedHeading {...{ size: 'h1', solutionButtonEnabled, children }} />,
      [BLOCKS.HEADING_2]: (node, children) => <LinkedHeading {...{ size: 'h2', solutionButtonEnabled, children }} />,
      [BLOCKS.HEADING_3]: (node, children) => <LinkedHeading {...{ size: 'h3', solutionButtonEnabled, children }} />,
      [BLOCKS.HEADING_4]: (node, children) => <LinkedHeading {...{ size: 'h4', solutionButtonEnabled, children }} />,
      [BLOCKS.HEADING_5]: (node, children) => <LinkedHeading {...{ size: 'h5', solutionButtonEnabled, children }} />,
      [BLOCKS.HEADING_6]: (node, children) => <LinkedHeading {...{ size: 'h6', solutionButtonEnabled, children }} />,
      [BLOCKS.TABLE]: (node, children) => <table>{children}</table>,
      [BLOCKS.TABLE_ROW]: (node, children) => <StyledTr>{children}</StyledTr>,
      [BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => <th>{children}</th>,
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entry = node.data.target.sys.id;
        const reference = getReferenceByContentfulId(entry);
        const { __typename } = reference;
        switch (__typename) {
          case 'ContentfulQuotedContent':
            const quote = !!reference?.content
              ? reference?.content?.childMarkdownRemark.html
              : `Missing quote in QuotedContent ${reference?.reference}`
            return (
              <ContentfulQuotedContent>
                <QuotedContentInner
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(quote),
                  }}
                />
              </ContentfulQuotedContent>
            );
          case 'ContentfulArcade':
            const { arcadeURL, aspectPercent } = reference;
            return (
              <Arcade {...{ arcadeURL, aspectPercent }} />
            );
          case 'ContentfulVimeo':
            return (
              <p><VimeoEmbed id={reference?.vimeoID} /></p>
            );
          case 'ContentfulYouTube':
            return (
              <YouTubeEmbed id={reference?.youtubeID} />
            );
          case 'ContentfulEnhancedImage':
            return (
              <EnhancedImage node={reference} background={chaos} />
            )
          case 'ContentfulBanner':
            return (
              <PromotionalBanner
                background={reference?.style}
                subtitle={reference?.assetType}
                content={reference?.title}
                buttonLabel={reference?.cta}
                buttonTo={reference?.link}
                image={reference?.coverImage}
                description={reference?.bannerDescription?.childMarkdownRemark?.html}
              />
            );
          case 'ContentfulRawHtml':
            return getWrappedHtmlElement(reference);
          case 'ContentfulCodeSnippet':
            const code = reference?.code?.code
            const language = reference?.language;
            const fileName = reference?.fileName;
            return (
              <StyledHighlight language={language} children={code} fileName={fileName}/>
            );
          case 'ContentfulMdxCodeSnippet':
            const codeSnippetHtml = reference?.snippet?.childMarkdownRemark?.html;
            const codeSnippet = reference?.snippet?.snippet;

            return (
              <MdxHighlight body={codeSnippetHtml} snippet={codeSnippet} />
            );
          default:
            return null;
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const entry = node.data.target.sys.id;
        const reference = getReferenceByContentfulId(entry);
        const { __typename, gatsbyImageData } = reference;
        if (__typename === 'ContentfulAsset') {
          const image = getImage(gatsbyImageData);
          return (
            <p>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <a className='gatsby-resp-image-link' target='_blank' href={image?.images?.fallback?.src}>
                  <GatsbyImage alt={reference?.description} image={image} className='gatsby-resp-image-wrapper'/>
                </a>
              </div>
            </p>
          );
        }
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const entry = node.data.target.sys.id;
        const reference = getReferenceByContentfulId(entry);
        if (reference.__typename === 'ContentfulRawHtml') {
          return getWrappedHtmlElement(reference);
        }
      },
    }
  };
};

const getWrappedHtmlElement = (reference) => {
  const html = reference?.html?.html;
  const wrapper = reference?.wrapper || '<p>';
  const __html = DOMPurify.sanitize(html);
  switch (wrapper) {
    case '<div>':
      return (
        <div dangerouslySetInnerHTML={{ __html }}/>
      );
    case '<p>':
      return (
        <p dangerouslySetInnerHTML={{ __html }}/>
      );
    case '<span>':
      return (
        <span dangerouslySetInnerHTML={{ __html }}/>
      );
  }
};

const wrapMarkupNodes = (document) => {
  document.content = [{
    nodeType: 'paragraph',
    data: {
      __typename: 'MarkupBlock'
    },
    content: document.content
  }];
  return document;
};

const RichTextRenderer = ({ richTextDocument }) => {
  const documentJson = wrapMarkupNodes(JSON.parse(richTextDocument.raw));
  return documentToReactComponents(documentJson, getRichTextRenderOptions(documentJson, richTextDocument.references));
};

export default RichTextRenderer;

const StyledHighlight = styled(Highlight)`
  pre.filename-layout {
    margin-top: 0;
    margin-left: -1rem;
    margin-right: -1rem;
    ${mqMin('md')}{
      margin: 0 -1rem 1rem;
    }
  }
  div.button-overlay {
    right: 2rem;
    z-index: 10;
  }
`;

const StyledLi = styled.li`
  p {
    margin: 0;
  }
`;

const StyledTr = styled.tr`
  p {
    margin: 0;
  }
`;

const ContentfulQuotedContent = styled(Testimonial)`
  padding: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  border-radius: 0;
  ${backgroundMixin} ${mqMin('md')} {
    padding: 2rem;
    margin-left: -2rem;
    margin-right: -2rem;
  }
`;

const QuotedContentInner = styled.div`
  font-weight: 100;
  font-size: 1.125rem;

  *:last-child {
    margin-bottom: 0;
  }

  ${mqMin('md')} {
    font-size: 1.5rem;
  }
`;

const StyledMarkup = styled(Prose)`
  margin-bottom: 1rem;

  > *:last-child {
    margin-bottom: 0;
  }
`;
